<template>
  <div class="dealer-company-detail">
    <div class="dealer-company-detail-title">
      <span class="back" @click="handleGoBack"><i class="el-icon-back"></i> 返回</span>
      <span class="company-name">{{company.name}}</span>
      <span class="company-duty-name">责任人:{{company.managerEmployeeName}}</span>
      <span class="company-duty-phone">联系人:{{company.contactEmployeeName}}/{{company.contactEmployeePhone}}</span>
      <span class="export" @click="showExport"><i class="el-icon-s-order"></i> 导出对账单</span>
      <span class="export" @click="showEditCompany"><i class="el-icon-setting"></i> 基础信息设置</span>
    </div>

    <el-row class="company">
      <el-col :span="14" class="company-info">
        <div class="company-free-progress">
          <el-progress type="circle" :percentage="100" :width="120" v-if="company.freeCups === 0"></el-progress>
          <el-progress type="circle"
                       :percentage="parseInt((company.usableFreeCups * 100 / company.freeCups).toFixed(2))" :width="120"
                       v-else></el-progress>
        </div>
        <div class="company-seller-info info">
          <div class="company-seller-info-item">
            <p class="company-seller-info-label">剩余免费杯量 / 已用免费杯量</p>
            <p class="company-seller-info-value">{{ company.usableFreeCups | I1000}} /
              {{ (company.freeCups - company.usableFreeCups) | I1000}}</p>
          </div>
          <div class="company-seller-info-item">
            <p class="company-seller-info-label">计费杯量</p>
            <p class="company-seller-info-value">{{company.cups - (company.freeCups - company.usableFreeCups) |
              I1000}}</p>
          </div>
          <div class="company-seller-info-item">
            <p class="company-seller-info-label">本月累积杯量</p>
            <p class="company-seller-info-value">{{company.cups|I1000}}</p>
          </div>
        </div>

        <div class="company-seller-info money">
          <div class="company-seller-info-item">
            <p class="company-seller-info-label">营收金额</p>
            <p class="company-seller-info-value"> ¥ {{((company.rent + company.revenue) / 100) | F1000}}</p>
          </div>
          <div class="company-seller-info-item">
            <p class="company-seller-info-label">销售金额</p>
            <p class="company-seller-info-value"> ¥ {{(( company.revenue) / 100) | F1000}}</p>
          </div>
        </div>
      </el-col>
      <el-col :span="9" class="company-employee-info">
        <div class="company-employee-info-item">
          <span class="label">员工限定模式
            <el-tooltip class="item" effect="dark" content="可从基础信息设置中开启，限定模式开启后，每个员工只能免费喝限定杯" placement="bottom">
            <i class="el-icon-question"></i>
          </el-tooltip>
            </span>
          <el-switch
            :disabled="true"
            class="switch"
            :active-value="1"
            :inactive-value="0"
            v-model="company.employeeLimit"
            active-color="#13ce66"
            inactive-color="#f2f2f2">
          </el-switch>
        </div>
        <div class="company-employee-info-item">
          <span class="label">员工每月默认定额:</span>
          <el-input size="mini" v-model="company.employeeFreeCups" disabled></el-input>
        </div>

        <div class="company-employee-info-item">
          <span class="label">员工人数:</span>
          <span>{{company.employees}}</span>
        </div>
        <el-button type="primary" plain size="mini" @click="showCreateEmployee">新增员工</el-button>
      </el-col>
    </el-row>

    <el-tabs v-model="activeName" type="card" @tab-click="handleChangeTable">
      <el-tab-pane label="相关套餐" name="combination"></el-tab-pane>
      <!--      <el-tab-pane label="本月制作趋势" name="chart"></el-tab-pane>-->
      <el-tab-pane label="员工列表" name="employee"></el-tab-pane>
    </el-tabs>

    <el-row v-show="activeName ==='combination'">
      <el-col :span="12" class="combination-item">
        <div class="combination-item-content add" @click="handleBindCombination">
          <span>新增套餐</span>
        </div>
      </el-col>
      <el-col :span="12" v-for="(combination, key) in combinations" class="combination-item" :key="key">
        <div class="combination-item-content">
          <div class="combination-item-content-title">
            <p class="name">
              <span class="label">套餐名称:</span>
              <span class="value">{{combination.combinationName}}</span>
              <span>有效期:</span>
              <span>{{new Date(combination.startAt*1000).Format('yyyy-MM-dd')}} 至 {{new Date(combination.endAt*1000).Format('yyyy-MM-dd')}}</span>
            </p>

            <p class="right">
              <span class="unbind" @click="handleUnBind(combination, key)"><i
                class="iconfont iconjiebang"></i> 解绑</span>
            </p>
          </div>
          <p><span class="label">本月消费总杯量 / 免费杯量:</span>
            <span>{{combination.makeCups}} / {{combination.hasUseFreeCups}}</span></p>
          <p><span class="label">套餐免费杯量:</span> <span>{{combination.freeCups}}</span></p>
          <p><span class="label">核计价格:</span> <span>{{(combination.rent+combination.revenue)/100 | F1000}}</span></p>
          <div class="combination-btn">
            <div @click="showCombinationExtend(combination, key)">续约</div>
            <div @click="showUseCondition(combination)">使用状况</div>
            <div @click="showBindDevice(combination)">绑定设备</div>
            <div @click="showHasBindDevice(combination.combinationUuid)">设备列表</div>
          </div>
        </div>
      </el-col>

    </el-row>
    <c-dialog :visible.sync="editCompanyVisible" title="基础设置" :width="'40rem'" :height="'42rem'">
      <div class="create-company">
        <el-form label-width="100px" size="small">
          <el-form-item label="客户名称">
            <el-input v-model="editCompany.name"></el-input>
          </el-form-item>

          <el-form-item label="客户对接人员">
            <el-select v-model="editCompany.managerEmployeeUuid" placeholder="请选取客户对接人员">
              <el-option :label="employee.name" :value="employee.uuid" v-for="employee in employees"></el-option>
            </el-select>
            <span class="explain">客户后续服务对接联系人</span>
          </el-form-item>

          <el-form-item label="销售人员">
            <el-select v-model="editCompany.contactEmployeeUuid" placeholder="请选取销售人员">
              <el-option :label="employee.name" :value="employee.uuid" v-for="employee in employees"></el-option>
            </el-select>
            <span class="explain">每个销售人员仅能看到当前及下级部门中相关客户</span>
          </el-form-item>

          <el-form-item label="员工限定模式">
            <el-switch
              class="switch"
              :active-value="1"
              :inactive-value="0"
              v-model="editCompany.employeeLimit"
              active-color="#13ce66"
              inactive-color="#f2f2f2">
            </el-switch>
            <span class="explain"></span>
          </el-form-item>

          <el-form-item label="员工限定杯量" v-show="editCompany.employeeLimit ==1">
            <el-input size="small" v-model="editCompany.employeeFreeCups"></el-input>
            <span class="explain">0为不限定，若员工消费超出当前数值采用套餐价计费</span>
          </el-form-item>

          <el-form-item label="客户地址">
            <map-container class="map" :lat.sync="editCompany.lat" :lng.sync="editCompany.lng"
                           :province.sync="editCompany.province"
                           :city.sync="editCompany.city" :address.sync="editCompany.address"></map-container>
            <p>地址信息:{{editCompany.province}}/{{editCompany.city}}/ {{editCompany.address}}</p>

          </el-form-item>


          <el-form-item>
            <el-button type="primary" @click="handleEditCompany">更新数据</el-button>
            <el-button @click="editCompanyVisible=false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </c-dialog>
    <detail-chart v-show="activeName ==='chart'" class="detail-chart"></detail-chart>
    <detail-employee v-show="activeName ==='employee'" ref="employee"></detail-employee>

    <c-dialog :visible.sync="bindCombinationVisible" :title="'套餐列表'" width="72rem" height="36rem">
      <combination :show-opr="false" :un-use="combinationUuids" @bind="handleBind"></combination>
    </c-dialog>

    <c-dialog :visible.sync="createEmployeeVisible" title="新增员工" :width="'40rem'" :height="'24rem'">
      <div class="create-company">
        <el-form label-width="100px" size="small">
          <el-form-item label="员工名称">
            <el-input v-model="employee.name"></el-input>
          </el-form-item>
          <el-form-item label="员工手机号">
            <el-input v-model="employee.phone"></el-input>
          </el-form-item>

          <el-form-item label="员工密码">
            <el-input v-model="employee.password"></el-input>
            <span class="explain">员工有效身份凭证</span>
          </el-form-item>

          <el-form-item label="员工限额杯量">
            <el-input v-model="employee.employeeFreeCups" type="number"></el-input>
            <span class="explain">每月员工最大免费杯量</span>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="handCreateEmployee">保存</el-button>
            <el-button @click="createEmployeeVisible=false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </c-dialog>

    <c-dialog :visible.sync="bindVisible" :width="'42rem'" :height="'36rem'">
      <div slot="title" class="input-title">
        <p class="input-title-text">设备列表</p>
        <div class="input-title-container">
          <el-button size="mini" type="primary" plain @click="handleBindDevice">绑定设备</el-button>
        </div>

        <div class="input-title-filter">
          <p>过滤条件:</p>
          <el-input v-model="bind.name" placeholder="请输入设备名称" @change="inputChange" size="mini"/>
        </div>

      </div>

      <devices ref="devices"></devices>
    </c-dialog>

    <c-dialog :visible.sync="showDeviceVisible" :width="'42rem'" :height="'36rem'" title="已绑设备列表">
      <el-table :data="devices"
                max-height="516px">
        <el-table-column label="设备编号" align="center" prop="deviceUUID"></el-table-column>
        <el-table-column label="设备名称" align="center" prop="deviceName"></el-table-column>
        <el-table-column label="操作" align="center" min-width="130">
          <template slot-scope="scope">
            <span class="unbind" @click="handleUnbindDevice(scope.deviceUuid, scope.$index)"> <i
              class="iconfont iconjiebang"></i>解绑</span>
          </template>
        </el-table-column>
      </el-table>
    </c-dialog>

    <c-dialog title="套餐续期" :visible.sync="dialogCombinationVisible" width="24rem" height="12rem">
      <el-form size="mini">
        <el-form-item label="套餐时间(月)">
          <el-input-number v-model="combination.month" :min="1" placeholder="请输入套餐续期时间"></el-input-number>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handCombinationExtend">确 定</el-button>
          <el-button @click="dialogCombinationVisible = false">取 消</el-button>
        </el-form-item>
      </el-form>
    </c-dialog>

    <c-dialog :visible.sync="showCombinationDrinkVisible" :width="'42rem'" :height="'36rem'" title="套餐使用状况">
      <el-table :data="combinationDrinks"
                max-height="516px">
        <el-table-column label="饮品名称" align="center" prop="drinkName"></el-table-column>
        <el-table-column label="饮品英文名称" align="center" prop="drinkEngName"></el-table-column>
        <el-table-column label="套餐免费杯量" align="center" prop="freeCups"></el-table-column>
        <el-table-column label="套餐内可用杯量" align="center" prop="usableFreeCups"></el-table-column>
        <el-table-column label="套餐外营收杯量" align="center" prop="revenueCups"></el-table-column>
      </el-table>
    </c-dialog>
  </div>

</template>

<script>
  import {
    companyDetail,
    companyCombinations,
    companyBindCombination,
    companyUnbindCombination,
    companyEmployeeLimit,
    companyEmployeeCreate,
    companyCombinationBindDevice,
    companyCombinationDevices,
    companyCombinationUnbindDevice,
    companyCombinationExtend,
    companyCombinationCondition
  } from '@/api/marketing/combination/company'
  import {checkObj, checkPhone} from '@/util/checkLogin'
  import CDialog from '@/components/Dialog'
  import Combination from '@/components/Combination'
  import {employeeCanChoice} from '@/api/account/employee'
  import MapContainer from '@/components/Map'
  import Devices from '@/components/Device'
  import {mapGetters} from "vuex";

  export default {
    name: '',
    components: {
      CDialog,
      Combination,
      MapContainer,
      Devices,
      DetailChart: function (resolve) {
        require(["./detail_chart.vue"], resolve);
      },
      DetailEmployee: function (resolve) {
        require(["./detail_employee.vue"], resolve);
      },
    },
    computed: {
      ...mapGetters(['companyItem'])
    },
    mounted: function () {
      this.initCompany()
    },
    activated: function () {
      this.bindCombinationVisible = false
      this.initCompany()
    },
    data: function () {
      return {
        activeName: 'combination',
        value: false,
        combinations: [],
        combinationUuids: [],
        employees: [],
        devices: [],
        bindCombinationVisible: false,
        dialogCombinationVisible: false,
        editCompanyVisible: false,
        createEmployeeVisible: false,
        bindVisible: false,
        showDeviceVisible: false,
        showCombinationDrinkVisible: false,
        employee: {
          companyUuid: '',
          name: '',
          phone: '',
          password: '',
          employeeFreeCups: '',
        },
        editCompany: {
          uuid: "",
          name: "",
          province: "",
          city: "",
          address: "",
          lng: "",
          lat: "",
          managerEmployeeUuid: '',
          contactEmployeeUuid: '',
          employeeLimit: 0,
          employeeFreeCups: 0,
        },
        company: {
          uuid: "",
          name: "",
          managerEmployeeUuid: "",
          managerEmployeeName: "",
          contactEmployeeUuid: "",
          contactEmployeeName: "",
          contactEmployeePhone: "",
          cups: 0,
          freeCups: 0,
          restFreeCups: 0,
          combinations: 0,
          employees: 0,
          employeeFreeCups: 0,
          revenue: 0,
          rent: 0,
          sumCups: 0,
          province: "",
          city: "",
          address: "",
          lng: "",
          lat: "",
          employeeLimit: 0
        },
        bind: {
          uuid: '',
          name: ''
        },
        combination: {
          index: 0,
          companyUuid: '',
          combinationUuid: '',
          month: 1
        },
        combinationDrinks: []
      }
    },

    methods: {
      showExport() {
        this.$message.warning('暂不支持对账单导出，敬请期待')
      },
      handCombinationExtend() {
        companyCombinationExtend(this.combination).then(res => {
          this.$message.success('延期成功')
          let end = new Date(this.combinations[this.combination.index].endAt * 1000)
          end.setMonth(end.getMonth() + this.combination.month)
          this.combinations[this.combination.index].endAt = end.getTime() / 1000
          this.dialogCombinationVisible = false
        })

      },
      showCombinationExtend(combination, index) {
        this.combination.month = 1
        this.combination.index = index
        this.combination.companyUuid = this.company.uuid
        this.combination.combinationUuid = combination.combinationUuid
        this.dialogCombinationVisible = true
      },
      showUseCondition(combination) {
        companyCombinationCondition({
          companyUuid: this.company.uuid,
          combinationUuid: combination.combinationUuid
        }).then(res => {
          this.combinationDrinks = res.data || []
          this.showCombinationDrinkVisible = true
        })
      },
      showBindDevice(combinator) {
        this.bind.uuid = combinator.combinationUuid
        this.bindVisible = true
        let vue = this

        this.$nextTick(function () {
          this.$refs.devices.loadDevices(vue.bind.name)
        })
      },
      showHasBindDevice(uuid) {
        companyCombinationDevices({uuid: uuid}).then(res => {
          this.devices = res.data || []
          this.showDeviceVisible = true
        })
      },
      handleUnbindDevice(uuid, index) {
        this.$confirm('此操作将会解绑设备与套餐关系, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          companyCombinationUnbindDevice({companyUuid: this.company.uuid, deviceUuid: uuid}).then(res => {
            this.devices.splice(index, 1)
            this.$message.success('解绑成功')
          })
        })

      },
      handleBindDevice() {
        let deviceUuid = this.$refs.devices.getDevices() || []
        if (deviceUuid.length === 0) {
          this.$message.warning("请先选取需要绑定的设备")
          return
        }

        companyCombinationBindDevice({
          companyUuid: this.company.uuid,
          combinationUuid: this.bind.uuid,
          deviceUuid: deviceUuid
        }).then(res => {
          this.$message.success('绑定成功')
          this.bindVisible = false
        })
      },
      inputChange(val) {
        this.$refs.devices.loadDevices(val)
      },
      handleGoBack() {
        this.$router.back()
      },
      showCreateEmployee() {
        this.employee = {
          companyUuid: this.company.uuid,
          name: '',
          phone: '',
          password: '',
          employeeFreeCups: this.company.employeeFreeCups,
        }
        this.createEmployeeVisible = true
      },
      handCreateEmployee() {
        let check = checkObj(this.employee, [
          {value: 'name', message: '请输入员工名称'},
          {value: 'phone', message: '请输入员工手机号码'},
          {value: 'password', message: '请输入员工密码'},
          {value: 'employeeFreeCups', message: '请输入员工最大免费杯量'},
        ])
        if (!check.status) {
          this.$message.warning(check.message)
          return
        }
        check = checkPhone(this.employee.phone)
        if (!check.status) {
          this.$message.warning(check.message)
          return
        }

        this.employee.employeeFreeCups = parseInt(this.employee.employeeFreeCups)

        companyEmployeeCreate(this.employee).then(res => {
          this.$message.success('创建成功')
          this.company.employees += 1
          if (this.$refs.employee) {
            this.$refs.employee.loadEmployee(this.company.uuid, true)
          }
          this.createEmployeeVisible = false
        })
      },
      handleEditCompany() {
        let check = checkObj(this.editCompany, [
          {value: 'name', message: '请输入公司名称'},
          {value: 'contactEmployeeUuid', message: '请选取客户对接人'},
          {value: 'managerEmployeeUuid', message: '请选取销售人员'},
          {value: 'province', message: '请设置客户地理位置'},
          {value: 'city', message: '请设置客户地理位置'},
          {value: 'address', message: '请设置客户地理位置'},
          {value: 'lng', message: '请设置客户地理位置'},
          {value: 'lat', message: '请设置客户地理位置'},
        ])
        if (!check.status) {
          this.$message.warning(check.message)
          return
        }
        this.editCompany.employeeFreeCups = parseInt(this.editCompany.employeeFreeCups)

        companyEmployeeLimit(this.editCompany).then(res => {

          this.company.name = this.editCompany.name
          this.company.contactEmployeeUuid = this.editCompany.contactEmployeeUuid
          this.company.managerEmployeeUuid = this.editCompany.managerEmployeeUuid
          this.company.province = this.editCompany.province
          this.company.city = this.editCompany.city
          this.company.address = this.editCompany.address
          this.company.lng = this.editCompany.lng
          this.company.lat = this.editCompany.lat
          this.company.employeeLimit = this.editCompany.employeeLimit
          this.company.employeeFreeCups = this.editCompany.employeeFreeCups
          this.editCompanyVisible = false
          this.$message.success('更新成功')
        })
      },
      showEditCompany() {
        if (this.employees.length === 0) {
          employeeCanChoice().then(res => {
            this.employees = res.data || []
          })
        }
        this.editCompany = {
          uuid: this.company.uuid,
          name: this.company.name,
          contactEmployeeUuid: this.company.contactEmployeeUuid,
          managerEmployeeUuid: this.company.managerEmployeeUuid,
          province: this.company.province,
          city: this.company.city,
          address: this.company.address,
          lng: this.company.lng,
          lat: this.company.lat,
          employeeLimit: this.company.employeeLimit,
          employeeFreeCups: this.company.employeeFreeCups,
        }
        this.editCompanyVisible = true
      },
      handleUnBind(combination, key) {
        this.$confirm('本月套餐未使用部分将会按天返回, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          companyUnbindCombination({
            combinationUuid: combination.combinationUuid,
            companyUuid: this.company.uuid
          }).then(res => {
            this.$message.success('解绑成功')
            this.initCompany()
          })
        }).catch(() => {

        })
      },
      handleBindCombination() {
        this.combinationUuids = []
        this.combinations.forEach(item => {
          this.combinationUuids.push(item.combinationUuid)
        })
        this.bindCombinationVisible = true
      },
      handleBind(uuid) {
        companyBindCombination({combinationUuid: uuid, companyUuid: this.company.uuid}).then(res => {
          this.$message.success('绑定成功')
          this.bindCombinationVisible = false
          this.initCompany()
        })
      },
      initCompany() {
        let c = this.companyItem || this.$route.params
        if (!c.uuid) {
          this.$router.back()
          return
        }
        this.activeName = 'combination'
        companyDetail({uuid: c.uuid}).then(res => {
          this.company = res.data
          this.company.uuid = c.uuid
        })

        companyCombinations({uuid: c.uuid}).then(res => {
          this.combinations = res.data || []
        })
      },
      handleChangeTable(item) {
        switch (item.name) {
          case "employee":
            if (this.$refs.employee) {
              this.$refs.employee.loadEmployee(this.company.uuid)
            }
            break;
        }
      }
    },
  }
</script>

<style lang="scss">
  .dealer-company-detail {
    min-height: 640px;
    border-radius: $border-radius;
    padding: $padding;
    @media (max-height: 768px) {
      min-height: 608px;
    }
    @include set_bg_color($background-color-light, $background-color-dark);

    &-title {
      height: 32px;
      line-height: 32px;

      .back {
        margin-right: $margin;
        display: inline-block;
      }

      .back:hover {
        cursor: pointer;
        color: $_theme_color;
      }

      .company-name {
        font-weight: 500;
        font-size: 16px;
        margin-right: $margin;
      }

      .company-duty-name, .company-duty-phone {
        font-size: 12px;
        @include set_border_color($--border-color, $--border-color-dark);
        margin-right: $padding;
      }
    }

    .company {
      margin-top: $margin;
      margin-bottom: 40px;

      .el-col {
        border-right: 1px solid;
        @include set_border_color($--border-color, $--border-color-dark);
        height: 160px;

        .info {
          flex: 1;
        }

        .money {
          width: 30%;
        }

        .company-seller-info {

          &-label {
            font-size: 12px;
            height: 12px;
            line-height: 1.3;
            @include set_font_color($--font-02-color, $--font-color-dark);
          }

          &-item {
            padding-top: $padding;
            padding-left: $padding;
          }

          &-value {
            font-size: 16px;

            .unit {
              font-size: 12px;
              @include set_font_color($--font-02-color, $--font-color-dark);
            }
          }
        }

      }

      .el-col:last-of-type {
        border: none;
      }
    }

    .company-info {
      display: flex;
      justify-items: center;

      .company-free-progress {
        padding: $padding;
        margin: 0 $margin;
      }
    }

    .company-employee-info {
      margin-left: 32px;

      &-item {
        display: flex;
        padding-bottom: $padding;

        .label {
          min-width: 120px;
          font-size: 12px;
          display: inline-block;
          @include set_font_color($--font-02-color, $--font-color-dark);
        }

        .el-input {
          max-width: 160px;
        }
      }
    }

    .detail-chart {
      height: 360px;
    }

    .export {
      cursor: pointer;
      color: $_theme_color;
      float: right;
      font-size: 13px;
      margin-right: $padding;
    }

    .combination-item {
      padding: 0 0 $padding $padding;


      .add {
        cursor: pointer;
        color: $_theme_color;
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: center;
        text-align: center;
      }

      &-content {
        height: 130px;
        border-radius: $border-radius;
        border: 1px solid;
        @include set_border_color($--color-info, $--font-02-color);
        padding: $padding;
        font-size: 13px;

        &-title {
          display: flex;

          .name {
            flex: 1;
          }

          .right {
            width: 12%;
            text-align: right;

            .unbind {
              cursor: pointer;
              margin-left: $padding;
            }

            .unbind:hover {
              color: $_theme_color;
            }
          }
        }

        &-title:after {
          content: ' ';
          display: block;
          clear: both;
        }

        .label {
          @include set_font_color($--font-02-color, $--font-color-dark);
          margin-right: 5px;
        }

        .value {
          font-weight: 500;
          display: inline-block;
          width: 184px;

        }

        .combination-btn {

          display: flex;

          div {
            flex: 1;
            text-align: center;
            font-weight: 500;
            margin-top: 8px;
            border-right: 2px solid;
            @include set_border_color($--color-info, $--font-02-color);
          }

          div:last-of-type {
            border: none;
          }

          div:hover {
            color: $_theme_color;
            cursor: pointer;
          }
        }
      }
    }

    .create-company {
      padding-top: $padding;
      padding-right: $padding;

      .el-input {
        max-width: 180px;

        .el-input__inner {

        }
      }

      .map {
        height: 240px;
      }

      .explain {
        font-size: 12px;
        display: inline-block;
        margin-left: $padding-8;
        @include set_font_color($--font-03-color, $--font-color-dark);
      }
    }

    .input-title {
      display: flex;
      padding: $padding 0;
      align-content: center;

      &-text {
        font-weight: 500;
        margin-right: $padding;
      }

      &-container {
        flex: 1;
        text-align: left;
      }

      &-filter {
        //flex: 1;
        display: flex;
        text-align: right;
        align-content: center;
        margin-right: $padding;

        p {
          width: 120px;
          font-size: 12px;
          margin-right: $padding;
          text-align: right;
        }
      }
    }

    .unbind {
      cursor: pointer;
      color: $_theme_color;
    }

  }
</style>

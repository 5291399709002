<template>
  <div :id="map"></div>
</template>

<script>
  export default {
    name: 'map-container',
    components: {},
    computed: {
      map() {
        let id = 'map-' + Math.random().toString(36).slice(-8)
        return id
      }
    },
    props: {
      lat: {
        type: String,
      },
      lng: {
        type: String,
      },
      province: {
        type: String,
      },
      city: {
        type: String,
      },
      address: {
        type: String,
      }
    },
    mounted: function () {
      let vue = this
      this.$nextTick(function () {
        vue.setMap()
      })
    },
    data: function () {
      return {}
    },
    methods: {
      updateInfo(name, value) {
        this.$emit('update:' + name, value);
      },
      setMap() {
        let vue = this
        setTimeout(function () {
          let mk = null
          let map = new BMap.Map(vue.map, {enableMapClick: false});
          if (vue.lat !== '') {
            let lat = parseFloat(vue.lat)
            let lng = parseFloat(vue.lng)
            var pt = new BMap.Point(lng, lat);
            mk = new BMap.Marker(pt)
            map.addOverlay(mk);
            map.centerAndZoom(pt, 16);
          } else {
            var point = new BMap.Point(116.404, 39.915);
            map.centerAndZoom(point, 11);
            new BMap.LocalCity().get(function (result) {
              map.setCenter(result.name);
            });
          }
          map.enableScrollWheelZoom(true);
          map.addControl(new BMap.ScaleControl({anchor: BMAP_ANCHOR_BOTTOM_LEFT}));
          map.addControl(new BMap.NavigationControl());

          var myGeo = new BMap.Geocoder();

          map.addEventListener("click", function (e) {
            var pt = e.point;
            myGeo.getLocation(pt, function (rs) {
              let province = rs.addressComponents.province
              let city = rs.addressComponents.city
              let address = rs.addressComponents.district + rs.addressComponents.street
              let lat = rs.point.lat
              let lng = rs.point.lng

              vue.updateInfo('province', province)
              vue.updateInfo('city', city)
              vue.updateInfo('address', address)
              vue.updateInfo('lat', lat + '')
              vue.updateInfo('lng', lng + '')

              if (mk !== null) {
                map.removeOverlay(mk)
              }
              mk = new BMap.Marker(pt)
              map.addOverlay(mk);

            })
          })
        }, 200)
      }
    },
  }
</script>

<style>

</style>

<template>
  <div class="combination">
    <el-form label-width="auto" :inline="true" size="mini" class="combination-form">
      <el-col :span="6">
        <el-form-item label="免费范围:">
          <div class="combination-filter">
            <el-input placeholder="最小值" v-model="pagination.cupLow" class="order-input-filter" type="number"/>
            <span>～</span>
            <el-input placeholder="最大值" v-model="pagination.cupUp" class="order-input-filter" type="number">
            </el-input>
          </div>

        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="饮品数:">
          <div class="combination-filter">
            <el-input placeholder="最小值" v-model="pagination.drinkLow" class="order-input-filter" type="number"/>
            <span>～</span>
            <el-input placeholder="最大值" v-model="pagination.drinkUp" class="order-input-filter" type="number">
            </el-input>
          </div>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="月租:">
          <div class="combination-filter">
            <el-input placeholder="最小值" v-model="pagination.rentLowVal" class="order-input-filter"
                      type="number"></el-input>
            <span>～</span>
            <el-input placeholder="最大值" v-model="pagination.rentUpVal" class="order-input-filter"
                      type="number"></el-input>
          </div>

        </el-form-item>
      </el-col>

      <el-col :span="5" :offset="1">
        <el-button type="primary" plain size="mini" @click="loadCombination">搜索</el-button>
        <el-button type="primary" size="mini" @click="handleBindCombination">绑定</el-button>
        <el-button type="primary" size="mini" @click="handleCreateCombination">创建套餐</el-button>
      </el-col>
    </el-form>
    <el-table
      :data="combinations"
      size="small"
      :row-class-name="tableRowClassName"
      border>

      <template slot="empty">
        <div class="combination-empty">
          <img :src="Empty"/>
          <p>这里空空如也 <span @click="handleCreateCombination">创建套餐</span></p>
        </div>
      </template>
      <el-table-column label="选择" width="60px" align="center" header-align="center" v-if="!showOpr">

                               
        <template slot-scope="scope">

                                     
          <el-radio :label="scope.row.uuid" v-model="selectCombination" :disabled="scope.row.disable"
                    @change.native="handleSelectCombination(scope.$index,scope.row)" style="margin-left: 10px;">&nbsp;&nbsp;
          </el-radio>

                                 
        </template>

                           
      </el-table-column>
      <el-table-column prop="uuid" label="编号" width="200" align="center"></el-table-column>
      <el-table-column prop="name" label="名称" width="240" align="center"></el-table-column>
      <el-table-column prop="months" label="套餐时长(月)" align="center"></el-table-column>
      <!--      <el-table-column prop="times" label="使用次数" align="center"></el-table-column>-->
      <el-table-column prop="drinks" label="饮品数" align="center"></el-table-column>
      <el-table-column prop="freeCups" label="免费杯数" align="center"></el-table-column>
      <el-table-column label="月租金" align="center">
        <template slot-scope="scope">
          <span>{{(scope.row.rent/100) | F1000}}</span>
        </template>
      </el-table-column>
      <el-table-column label="套餐单杯价格" align="center">
        <template slot-scope="scope">
          <span>{{(scope.row.singleCupPrice/100) | F1000}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="180" v-if="showOpr">
        <template slot-scope="scope">
          <el-button type="text" @click="handleEditCombination(scope.row)" size="mini">相关公司</el-button>
          <el-button type="text" @click="handleEditCombination(scope.row)" size="mini">编辑</el-button>
          <el-button type="text" @click="handleDeleteCombination(scope.row, scope.$index)" size="mini">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      :current-page="pagination.curPage"
      :page-size="pagination.limit"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total">
    </el-pagination>
  </div>

</template>

<script>
  import {combinationUnion, combinationList, combinationDelete} from '@/api/marketing/combination/index'

  import Empty from '@/assets/img/empty.png'

  export default {
    name: '',
    components: {},
    props: {
      showOpr: {
        type: Boolean,
        default: true,
      },
      unUse: {
        type: Array,
        default: function () {
          return []
        }
      }
    },
    mounted: function () {
      this.loadCombination()
    },
    activated() {
      this.loadCombination()
    },
    data: function () {
      return {
        combinations: [],
        selectCombination: '',
        Empty,
        pagination: {
          cupLow: '',
          cupUp: '',
          drinkLow: '',
          drinkUp: '',
          rentLowVal: '',
          rentUpVal: '',
          rentLow: 0,
          rentUp: 0,
          curPage: 1,
          limit: 10,
          total: 0,
        },
      }
    },
    methods: {
      tableRowClassName({row, rowIndex}) {
        if (rowIndex % 2 === 0) {
          return 'combination-row';
        }
        return '';
      },
      handleBindCombination() {
        if (this.selectCombination) {
          this.$emit('bind', this.selectCombination)
        } else {
          this.$message.warning('请选取对应的套餐')
        }

      },
      handleSelectCombination(index, value) {
        this.selectCombination = value.uuid
      },
      handleCreateCombination() {
        this.$router.push({name: 'marketing.combination.create'})
      },
      handleEditCombination(row) {
        this.$router.push({name: 'marketing.combination.edit', params: row})
      },

      handleDeleteCombination(row, index) {
        this.$confirm('删除套餐之前请确保未关联任何公司, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          combinationDelete({uuid: row.uuid}).then(res => {
            this.combinations.splice(index, 1)
          })
        }).catch(() => {

        })
      },
      handleCurrentChange(value) {
        if (this.pagination.rentUpVal) {
          this.pagination.rentUp = parseInt(this.pagination.rentUpVal, 10) * 100
        } else {
          this.pagination.rentUp = 0
        }

        if (this.pagination.rentLowVal) {
          this.pagination.rentLow = parseInt(this.pagination.rentLowVal, 10) * 100
        } else {
          this.pagination.rentLow = 0
        }
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit
        combinationList(this.pagination).then(res => {
          this.combinations = res.data || []
        })
      },

      loadCombination() {
        let vue = this
        if (this.pagination.rentUpVal) {
          this.pagination.rentUp = parseInt(this.pagination.rentUpVal, 10) * 100
        } else {
          this.pagination.rentUp = 0
        }

        if (this.pagination.rentLowVal) {
          this.pagination.rentLow = parseInt(this.pagination.rentLowVal, 10) * 100
        } else {
          this.pagination.rentLow = 0
        }


        combinationUnion(this.pagination).then(res => {
          let combinations = res.data.list || []
          if (vue.unUse.length !== 0) {
            combinations.map((combination, key) => {
              let val = false
              for (let i = 0; i < vue.unUse.length; i++) {
                if (combination.uuid === vue.unUse[i]) {
                  val = true;
                  break;
                }
              }
              this.$set(combinations[key], 'disable', val);
            })
            vue.combinations = combinations
          } else {
            vue.combinations = combinations
          }
          vue.pagination.total = parseInt(res.data.total)
        })
      },
    },
  }
</script>

<style lang="scss">

  body.dark {
    .combination {
      .el-input__inner {
        border-color: #858992;
      }
    }
  }

  .combination {
    min-height: 608px;
    border-radius: $border-radius;
    @media (max-height: 768px) {
      min-height: 576px;
    }

    &-form {
      margin-top: $padding;
    }

    &-filter {
      display: flex;

      span {
        margin: 0 $padding-8;
      }
    }

    .order-input-filter {
      .el-input__inner {
        width: 88px;
        padding: 0;
        padding-left: 15px;
      }

    }

    .combination-empty{
      img{
        max-width: 320px;
        margin-top: $margin;
      }
      span{
        color: $_theme_color;
        cursor: pointer;
      }
    }

    .el-table {
      .el-radio__label {
        display: none;
      }
    }

    .el-table .combination-row {
      background: #267aff14;

    }

  }
</style>

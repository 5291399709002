import request from '@/util/request'

export const companyUnion = (params) => {
  return request({
    'url': '/api/v1/marketing/company/union',
    'method': 'GET',
    params
  })
}

export const companyList = (params) => {
  return request({
    'url': '/api/v1/marketing/company/list',
    'method': 'GET',
    params
  })
}

export const companyDetail = (params) => {
  return request({
    'url': '/api/v1/marketing/company/detail',
    'method': 'GET',
    params
  })
}

export const companyCombinations = (params) => {
  return request({
    'url': '/api/v1/marketing/company/combinations',
    'method': 'GET',
    params
  })
}

export const companyBindCombination = (params) => {
  return request({
    'url': '/api/v1/marketing/company/bindCombination',
    'method': 'GET',
    params
  })
}

export const companyUnbindCombination = (params) => {
  return request({
    'url': '/api/v1/marketing/company/unbindCombination',
    'method': 'GET',
    params
  })
}

export const companyCreate = (data) => {
  return request({
    'url': '/api/v1/marketing/company/create',
    'method': 'POST',
    data
  })
}

export const companyEmployeeLimit = (data) => {
  return request({
    'url': '/api/v1/marketing/company/employeeLimit',
    'method': 'POST',
    data
  })
}

export const companyEmployeeCreate = (data) => {
  return request({
    'url': '/api/v1/marketing/company/createEmployee',
    'method': 'POST',
    data
  })
}

export const companyEmployeeUnion = (params) => {
  return request({
    'url': '/api/v1/marketing/company/employee/union',
    'method': 'GET',
    params
  })
}

export const companyEmployeeList = (params) => {
  return request({
    'url': '/api/v1/marketing/company/employee/list',
    'method': 'GET',
    params
  })
}


export const companyEmployeeDelete = (params) => {
  return request({
    'url': '/api/v1/marketing/company/employee/delete',
    'method': 'GET',
    params
  })
}

export const companyEmployeeResetPassword = (data) => {
  return request({
    'url': '/api/v1/marketing/company/employee/resetPassword',
    'method': 'POST',
    data
  })
}

export const companyEmployeeResetCups = (data) => {
  return request({
    'url': '/api/v1/marketing/company/employee/resetCups',
    'method': 'POST',
    data
  })
}

export const companyCombinationBindDevice = (data) => {
  return request({
    'url': '/api/v1/marketing/company/combination/bindDevice',
    'method': 'POST',
    data
  })
}

export const companyCombinationDevices = (params) => {
  return request({
    'url': '/api/v1/marketing/company/combination/devices',
    'method': 'GET',
    params
  })
}

export const companyCombinationUnbindDevice = (params) => {
  return request({
    'url': '/api/v1/marketing/company/combination/unbindDevice',
    'method': 'GET',
    params
  })
}

export const companyCombinationExtend = (data) => {
  return request({
    'url': '/api/v1/marketing/company/combination/extend',
    'method': 'POST',
    data
  })
}

export const companyCombinationCondition = (params) => {
  return request({
    'url': '/api/v1/marketing/company/combination/condition',
    'method': 'GET',
    params
  })
}
